.cinepoint-webapp {
  .p-inputgroup {
    display: flex;
    align-items: stretch;
    width: 100%;
  }
  .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child,
  .p-inputgroup > .p-inputwrapper:first-child > .p-component,
  .p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .p-inputgroup-addon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  -inputgroup-addon {
    padding: 0.5rem;
  }
  .p-inputgroup-addon {
    background: #ffffff;
    color: #64748b;
    border-top: 1px solid #cbd5e1;
    border-left: 1px solid #cbd5e1;
    border-bottom: 1px solid #cbd5e1;
    padding: 0.5rem 0.75rem;
    min-width: 2.5rem;
  }

  .p-inputgroup > .p-component,
  .p-inputgroup > .p-inputwrapper > .p-inputtext,
  .p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0;
  }

  .p-inputgroup-addon:last-child,
  .p-inputgroup button:last-child,
  .p-inputgroup input:last-child,
  .p-inputgroup > .p-inputwrapper:last-child > .p-component,
  .p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .p-inputgroup > .p-component + .p-inputgroup-addon, .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon, .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
  }

  .p-inputgroup-addon:last-child {
    border-right: 1px solid #ced4da !important;
    border-right-width: 1px !important;
    border-right-style: solid !important;
    border-right-color: rgb(206, 212, 218) !important;
}
}
