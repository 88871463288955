.cinepoint-webapp {
  .p-checkbox {
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: bottom;
    position: relative;
  }

  .p-checkbox .p-checkbox-box {
    @apply w-[16px] h-[16px];
    border: 2px solid #ced4da;
    background: #ffffff;
    color: #212529;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);

    .p-checkbox-icon {
      @apply w-[11px] h-[11px];
    }
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    @apply bg-primary-500 border-primary-500;
  }

}
