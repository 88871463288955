.cinepoint-webapp {
  .p-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .p-tag {
    background: var(--primary-100);
    color: var(--primary-700);
  }

  .p-tag {
    background: #3b82f6;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 6px;
  }

  .p-tag-icon,
  .p-tag-value,
  .p-tag-icon.pi {
    line-height: 1.5;
  }

  .p-tag.p-tag-rounded {
    border-radius: 10rem;
  }
}
