@mixin generate-icon-class($icon-name) {
  .icon-#{$icon-name} {
    display: inline-block !important;
    width: calc(1em + 2px);
    height: calc(1em + 2px);
    background-color: currentColor;
    mask-image: url("/assets/icons/icon-#{$icon-name}.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    -webkit-mask-image: url("/assets/icons/icon-#{$icon-name}.svg");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
  }
}

$icon-names: 
  comment, sliders-h, delete,
  equals, flash, cinepoint, user-circle, profile, logout, check-mark,
  check-circle, cross-circle, check-filled, play-button, compare-button;

@each $icon-name in $icon-names {
  @include generate-icon-class($icon-name);
}
