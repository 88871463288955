.cinepoint-webapp {
  .p-badge.p-badge-info {
    @apply bg-primary-50 text-primary-700 text-xs font-semibold flex justify-center items-center;

  }

  .badge-loading {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
    width: 100%;
    height: calc(1em + 4px);
    border-radius: 8px;
    vertical-align: middle;
  
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: "";
    }
  
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
