.cinepoint-webapp {
  ul.p-stepper-nav {
    max-width: 640px;
    margin: auto;
  }

  span.p-stepper-number {
    @apply rounded-lg bg-neutral-100 text-sm font-semibold text-neutral-500;
  }

  .p-stepper .p-stepper-header .p-stepper-title,
  .p-stepper .p-stepper-header .p-stepper-number {
    @apply text-neutral-500 text-sm;
  }

  .p-stepper .p-stepper-header.p-highlight .p-stepper-title {
    @apply text-sm font-semibold text-neutral-800;
  }

  .p-stepper .p-stepper-separator {
    @apply bg-neutral-300;
  }

  .p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator,
  .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
    @apply bg-primary-500;
  }

  .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
    @apply text-white;
  }

}
