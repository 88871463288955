.cinepoint-webapp {
  .cine-error {
    display: none;
  }
  .carousel-nav-button {
    @apply w-[40px] h-[40px] bg-white border-[#EAECF0] text-[#667085];
  }
  .p-radiobutton.p-component .p-radiobutton-box {
    @apply border-neutral-300 border;
  }
  .p-dropdown {
    width: 100%;
    padding-right: 30px;
  }
  textarea {
    width: 100%;
  }
  .p-inputwrapper.ng-touched.ng-invalid,
  .p-inputwrapper.ng-touched.ng-invalid input.p-inputtext,
  .p-inputtext.ng-touched.ng-invalid,
  .p-inputtext.ng-dirty.ng-invalid {
    &:not(.p-dropdown) {
      @apply border-danger-400;

      & + .cine-error {
        display: inline;
      }

      & eyeslashicon,
      & eyeicon {
        margin-right: 20px;
      }
    }
  }

  .cine-disabled {
    opacity: 1;
    background-color: #e2e8f0;
    color: #64748b;
  }

  .p-element.p-inputwrapper .p-dropdown.cine-disabled-dropdown,
  .p-element.p-inputwrapper
    .p-dropdown.cine-disabled-dropdown
    .p-element.p-dropdown-label,
  .cine-disabled-button {
    opacity: 1;
    @apply bg-disabled-button text-disabled-button-text #{!important};
  }

  .fgroup {
    @apply flex flex-col;

    p-password.p-element div.p-password input.p-inputtext.p-password-input,
    p-password .p-password.p-component,
    input {
      @apply w-full  h-[48px] #{!important};
    }

    .invalid-feedback {
      @apply w-full text-wrap text-danger-400 text-sm mb-[4px] break-words;
    }

    .fphone {
      @apply flex flex-row max-h-[48px];

      div.p-dropdown {
        @apply px-[12px] text-neutral-900 bg-neutral-100 w-[90px] max-w-[90px] h-[48px] rounded-none rounded-l-md #{!important};

        .p-dropdown-label {
          @apply bg-neutral-100 #{!important};
        }

        .p-dropdown-trigger {
          @apply text-neutral-500 #{!important};
        }
      }

      input.p-inputtext.p-component {
        @apply w-[310px] rounded-none rounded-r-md;
      }
    }
  }

  input[readonly],
  textarea[readonly] {
    // background-color: var(--secondary-500) !important;
    // border-color: rgb(208 213 221 / var(--tw-border-opacity)) !important;
    box-shadow: none !important;
    cursor: not-allowed;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}
