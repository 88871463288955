.cinepoint-webapp {
  .p-radiobutton-box.p-disabled {
    background-color: var(--gray-200) !important;
  }
  .p-radiobutton.p-component.p-radiobutton-disabled {
    cursor: default;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #3b82f6;
    background: #ffffff;
  }

  .p-radiobutton .p-radiobutton-box {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
  }

  .p-radiobutton .p-radiobutton-box {
    border: 1px solid #cbd5e1;
    background: #ffffff;
    width: 1.25rem;
    height: 1.25rem;
    color: #334155;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
  }

  .p-radiobutton-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .p-radiobutton-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p-radiobutton {
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: bottom;
    position: relative;
  }
  .p-radiobutton {
    width: 1.25rem;
    height: 1.25rem;
  }

  .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    transform: translateZ(0) scale(1);
    visibility: visible;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 0.75rem;
    height: 0.75rem;
    transition-duration: 0.2s;
    background-color: #3b82f6;
  }

  .p-radiobutton-icon {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateZ(0) scale(0.1);
    border-radius: 50%;
    visibility: hidden;
  }

  ::selection {
    background-color: var(--highlight-bg);
    color: var(--highlight-text-color);
  }

  .p-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
