

.cinepoint-webapp {
  .p-button {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    color: #ffffff;
    background: #007bff;
    border: 1px solid #007bff;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
    &.outlined {
      color: #007bff;
      background: #ffff;
      border: 1px solid #007bff;
    }
  }

  .p-button:focus { outline: none;}

  .p-button.p-button-secondary,
  .p-buttonset.p-button-secondary > .p-button,
  .p-splitbutton.p-button-secondary > .p-button {
    color: #ffffff;
    background: #6c757d;
    border: 1px solid #6c757d;
    &.outlined {
      color: #344054;
      background: #ffff;
      border: 1px solid #D0D5DD;
    }
  }

  .p-button.p-button-danger,
  .p-buttonset.p-button-danger > .p-button,
  .p-splitbutton.p-button-danger > .p-button {
    color: #ffffff;
    background: #dc3545;
    border: 1px solid #dc3545;
    &.outlined {
      color: #dc3545;
      background: #ffff;
      border: 1px solid #dc3545;
    }
  }

  .p-button.p-button-help,
  .p-buttonset.p-button-help > .p-button,
  .p-splitbutton.p-button-help > .p-button {
    color: #ffffff;
    background: #6f42c1;
    border: 1px solid #6f42c1;
  }

  .p-button.p-button-success,
  .p-buttonset.p-button-success > .p-button,
  .p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: #28a745;
    border: 1px solid #28a745;
  }



  .cine-nav-menu {
    .p-button {
      border: 1px solid rgba(248,250,255, 0);
      background: rgba(248,250,255, 0);
    }

    .active-menunav,
    .p-button:hover,
    .p-button:focus,
    .p-button:active {
      background: rgba(248,250,255, 0.2);
      @apply font-semibold;

      &.pages-menunav {
        @apply bg-primary-50 text-primary-500 font-semibold;
      }
    }
  }

  .cine-button-danger {
    @apply rounded-lg border-danger-500 text-danger-500 bg-white w-full #{!important};
  }

  .cine-button-primary {
    @apply rounded-lg border-primary-500 bg-primary-500 w-full #{!important};
  }

  .cine-button-secondary {
    @apply rounded-lg border-primary-500 bg-white text-primary-500 w-full #{!important};
  }

  .cine-button-white {
    @apply rounded-lg border-neutral-300 bg-white text-neutral-700 w-full #{!important};
  }

  .cine-button-dialog-reject {
    @apply text-sm rounded-lg border-neutral-300 bg-white text-neutral-700 w-full #{!important};
  }

  .cine-button-dialog-accept {
    @apply text-sm rounded-lg border-danger-500 bg-danger-500 text-white w-full #{!important};
  }


}

