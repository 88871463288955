.cinepoint-webapp {
  .p-datatable .p-sortable-column .p-column-title,
  .p-datatable .p-sortable-column .p-sortable-column-icon,
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    vertical-align: middle;
  }

  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #6c757d;
    margin-left: 0.5rem;
  }

  .p-datatable-thead {
    .p-icon {
      display: inline-block;
    }
  }

  .p-datatable .p-datatable-thead > tr > th {
    @apply p-[12px] gap-[12px] text-xs text-neutral-500 font-medium text-center border-neutral-200;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    background: #ffffff;
  }


  .p-datatable .p-datatable-tbody > tr > td {
    @apply py-[16px] px-[24px] gap-[16px] text-base text-neutral-900 font-medium border-neutral-200
      text-center border-solid justify-center items-center;
    border-width: 0 0 1px 0;
    background: #ffffff;

    & > * { display: unset;}
  }

  .p-datatable .p-datatable-tbody > tr:nth-child(odd) > td {
    @apply bg-neutral-50;
  }

  .p-sortable-column .p-element.p-icon-wrapper svg {
    color: rgb(102 112 133 / 50%);
    height: calc(1rem - 4px);
  }

  .p-sortable-column .p-element.p-icon-wrapper {
    font-family: 'primeicons';
  }

  .p-sortable-column sortamountdownicon.p-element.p-icon-wrapper svg {
    color: rgb(102 112 133 / 100%);
  }

  .p-sortable-column sortamountupalticon.p-element.p-icon-wrapper svg {
    color: rgb(102 112 133 / 100%);
  }

  .cine-paginator {
    &.p-paginator {
      @media screen and (max-width: 767px) { // look @ other.constants.ts/ MEDIA_BREAKPOINT_MIN.md
        @apply grid grid-flow-row grid-rows-2 gap-4 px-0 pb-0 ;
        .p-paginator-left-content {
          @apply col-span-3 w-full  ;
          > :first-child {
            @apply justify-between ;
          }
        }
      }
    }
    & > p-dropdown.p-element { display: none;}
    p-dropdown.p-element {

      & > div.p-dropdown {
        @apply border rounded-lg border-neutral-300 px-[12px] py-[10px] h-auto items-center;

        & > span.p-dropdown-label {
          @apply border-0 p-0 mr-[8px];
        }

        & > div.p-dropdown-trigger {
          @apply p-0 w-auto;
        }
      }
    }

    button.p-paginator-element {
      @apply text-neutral-500;

      &.p-paginator-prev,
      &.p-paginator-next {
        @apply text-neutral-800;
      }

      &.p-highlight {
        @apply bg-[#F2FEFF] border-[#56B1D7] border rounded-lg text-[#125482];
      }

    }
  }
}
