@mixin generate-pill-class($color) {
  .pill-#{$color} > span {
    @apply bg-#{$color} py-[4px] px-[12px] gap-[4px] text-sm font-medium;
  }
}

$colors: danger-500, success-400, secondary-600;

.cinepoint-webapp {
  @each $c in $colors {
    @include generate-pill-class($c);
  }
}
