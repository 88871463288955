.cinepoint-webapp {
  .p-checkbox {
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: bottom;
    position: relative;
  }

  .p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #212529;
    border-radius: 4px;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  }

  .p-inputtext {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.75rem;
    border: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    appearance: none;
    border-radius: 4px;

    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #334155;
    background: #ffffff;
    padding: 0.5rem 0.75rem;
    border: 1px solid #cbd5e1;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    /* appearance: none; */
    border-radius: 6px;
  }
}
