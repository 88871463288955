.cinepoint-webapp {
  .p-component,
  .p-component * {
    box-sizing: border-box;
  }

  .section-title {
    @apply flex flex-row flex-wrap items-center pt-[40px] lg:pt-[64px] pb-[32px] lg:pb-[40px] gap-[16px] lg:gap-[32px];
  }

  .card-shadow-box {
    box-shadow: 0px 4px 6px -2px #10182808;
    box-shadow: 0px 12px 16px -4px #10182814;
  }

}

.dialog-no-padding {
  padding: 0;

  .p-dialog-content {
    padding: 0;
  }
}
