.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #ffffff;
  border-color: #e2e8f0;
  color: #334155;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}


.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  border-radius: 6px !important;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.125rem 1.125rem 1.125rem 1.125rem;
  border: 0 none;
  color: #64748b;
  background: #ffffff;
  font-weight: 600;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
}

.p-accordion-header-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  text-decoration: none;
}

.p-accordion p-accordiontab .p-accordion-tab {
  border-bottom: 1px solid #e2e8f0;
}

.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}

.p-accordion-header-text {
  @apply text-neutral-800;
}
