@layer primeng, tailwind-base, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;

  // define revert css here
  .no-tailwindcss-base {

    blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
      margin: revert;
    }

    ol,
    ul {
      list-style: revert;
      margin: revert;
      padding: revert;
    }
  }
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.min.css";
@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";

@import 'assets/styles/core.scss';
@import 'assets/styles/variables.scss';
@import 'assets/styles/fonts.scss';
@import 'assets/styles/accordion.scss';
@import 'assets/styles/badge.scss';
@import 'assets/styles/checkbox.scss';
@import 'assets/styles/text-util.scss';
@import 'assets/styles/calendar.scss';
@import 'assets/styles/tabs.scss';
@import 'assets/styles/buttons.scss';
@import 'assets/styles/common.scss';
@import 'assets/styles/icons.scss';
@import 'assets/styles/pills.scss';
@import 'assets/styles/table.scss';
@import 'assets/styles/dropdown.scss';
@import 'assets/styles/dialogs.scss';
@import 'assets/styles/controls.scss';
@import 'assets/styles/link.scss';
@import 'assets/styles/input.scss';
@import 'assets/styles/inputgroup.scss';
@import 'assets/styles/stepper.scss';
@import 'assets/styles/tag.scss';
@import 'assets/styles/toast.scss';
@import 'assets/styles/otp.scss';
@import 'assets/styles/typography.scss';
@import 'assets/styles/menu.scss';
@import 'assets/styles/scrollbars.scss';
@import 'assets/styles/radiobutton.scss';

@import 'assets/styles/spacings.scss';


