/* Poppins Regular (400) */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Regular.ttf') format('ttf'),
       url('/fonts/poppins/Poppins-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

/* Poppins Medium (500) */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Medium.ttf') format('ttf'),
       url('/fonts/poppins/Poppins-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

/* Poppins Medium Italic (500 Italic) */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-MediumItalic.ttf') format('ttf'),
       url('/fonts/poppins/Poppins-MediumItalic.ttf') format('ttf');
  font-weight: 500;
  font-style: italic;
}


body, * {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.text-description {
  @apply text-wrap text-left text-sm text-neutral-500;
}

.text-description-xs {
  @apply text-wrap text-left text-xs text-neutral-500;
}

.text-movie-title {
  @apply text-base text-neutral-900 font-semibold #{!important};
}

.text-movie-title-lg {
  @apply text-lg text-neutral-900 font-semibold #{!important};
}

