
@mixin full-height() {
  min-height: calc(100vh - var(--header-height));
}


html,
body,
app-root,
.cinepoint-webapp {
  @apply min-h-full h-full w-full m-0 p-0;
  // @include full-height();

  & router-outlet.full-h-container + * {
    @apply min-h-full w-full block;
    @include full-height();
  }

}

.cine-h-screen {
  @apply flex flex-col min-h-screen;
}

.cine-general-container-x {
  @apply px-[16px] lg:px-28;
}

.cine-general-container-y {
  @apply pb-[40px] pt-[20px] lg:pt-[40px] ;
}