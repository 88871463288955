.cinepoint-webapp {
  .cine-otp.ng-otp-input-wrapper .otp-input:nth-child(3) {
    margin-right: 30px !important;
  }

  .cine-otp.ng-otp-input-wrapper .otp-input.cine-otp-input {
    @apply rounded-[8px] border-neutral-300 p-[8px] gap-[8px] w-[48px] h-[48px] text-primary-500;

    &::placeholder {
      @apply text-neutral-300;
    }

    &:focus,
    &:not(:placeholder-shown) {
      @apply border-primary-500 text-primary-500;
    }

    &.cine-otp-error {
      box-shadow: 0px 0px 0px 4px #FEE7E1 !important;
      box-shadow: 0px 1px 2px 0px #1018280D !important;
      @apply border-danger-300 text-danger-500 #{!important};
    }

  }

  .cine-otp.ng-otp-input-wrapper:after {
    content: '-';
    position: relative;
    left: -50%;
    font-size: 24px;
    color: #D0D5DD;
  }
}
