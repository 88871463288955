.cinepoint-webapp {
  .p-calendar {
    position: relative;
    display: inline-flex;
    max-width: 100%;
  }

  .p-calendar .p-datepicker {
    min-width: 100%;
  }

  .p-datepicker-inline {
    display: inline-block;
    position: static;
    overflow-x: auto;
  }

  .p-datepicker {
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
  }

  .p-datepicker {
    padding: 0.75rem;
    background: #ffffff;
    color: #334155;
    border: 1px solid #cbd5e1;
    border-radius: 6px;
  }

  .p-datepicker .p-datepicker-header {
    padding: 0 0 0.5rem 0;
    color: #334155;
    background: #ffffff;
    font-weight: 500;
    margin: 0 0 0 0;
    border-bottom: 1px solid #e2e8f0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .p-datepicker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 1.75rem;
  }

  .p-datepicker table {
    width: 100%;
    border-collapse: collapse;
  }
  .p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0 0 0;
  }
  .p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0 0 0;
  }

  .p-datepicker table th {
    font-weight: 500;
  }

  .p-datepicker table th {
    padding: 0.25rem;
  }
  .p-disabled,
  .p-disabled * {
    cursor: default !important;
    pointer-events: none;
  }

  .p-datepicker table th > span {
    width: 2rem;
    height: 2rem;
  }
  .p-disabled,
  .p-disabled * {
    cursor: default !important;
    pointer-events: none;
  }
  .p-datepicker table td {
    padding: 0.25rem;
  }
  .p-datepicker td > span {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }

  .p-datepicker table td > span {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s, outline-color 0.2s;
    border: 1px solid transparent;
  }

  .p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-year:enabled:hover,
  .p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-month:enabled:hover {
    color: #3b82f6;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 1.75rem;
  }

  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #ffffff;
  }

  .p-datepicker .p-datepicker-header {
    padding: 0 0 0.5rem 0;
    color: #334155;
    background: #ffffff;
    font-weight: 500;
    margin: 0 0 0 0;
    border-bottom: 1px solid #e2e8f0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .p-datepicker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .p-datepicker-header .p-datepicker-title {
    margin: 0 auto;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #334155;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s, outline-color 0.2s;
    font-weight: 500;
    padding: 0.25rem;
  }

  .p-link {
    text-align: left;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
  }
}
