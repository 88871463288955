.cinepoint-webapp .cine-toast.p-toast {
  @apply w-[max-content] max-w-[100vw] fixed p-[16px] rounded-lg overflow-hidden;
  .p-toast-message {
    @apply border rounded-lg #{!important};

    .p-toast-message-content {
      .p-toast-icon-close { @apply hidden; }
      .p-toast-message-icon {
        @apply text-[18px]/[1.25rem];

        & > * {
          @apply w-[18px] h-[18px] absolute top-[12px] #{!important};
        }
      }

      .p-toast-summary {
        @apply text-sm font-semibold;
      }

      .p-toast-detail {
        @apply text-sm;
      }
    }

    &.p-toast-message-info {
      @apply bg-[#E3F5FC] text-info-500 border-info-500 #{!important};
    }

    &.p-toast-message-success {
      @apply bg-success-50 text-neutral-800 border-success-500 #{!important};
    }

    &.p-toast-message-danger,
    &.p-toast-message-error {
      @apply bg-[#f8d7da] text-[#721c24] border-[#721c24] #{!important};
    }
  }

}
