.cinepoint-webapp {
  .p-tabview .p-tabview-panels {
    @apply p-0 pt-[24px];
  }

  .p-tabview.p-component {
    .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav {
      @apply gap-[12px] border-b border-neutral-200 pb-px;

      li {
        @apply rounded-t-lg;

        a.p-element.p-tabview-nav-link {
          @apply bg-neutral-50 lg:min-w-[160px] h-[48px] p-[12px] lg:px-[16px] gap-[10px];

          span {
            @apply w-full text-base text-center font-normal text-neutral-500;
          }
        }

        &.p-highlight {
          a.p-element.p-tabview-nav-link {
            @apply bg-primary-500;

            span {
              @apply text-white;
            }
          }
        }
      }
    }
  }
}
