:root {
  --base-font-size: 16px; // Define variable to define rem value of font-size
}

@media screen and (max-width: 767px) { // look @ other.constants.ts/ MEDIA_BREAKPOINT_MIN.md
  :root {
    --base-font-size: 14px; // Smaller base font size for screens <= 1023px
  }
}

html {
  font-size: var(--base-font-size); // Apply the variable
}

h2 {
  font-size: calc(1rem + 4px);
  font-weight: 900;
}
h3 {
  font-size: calc(1rem + 2px);
  font-weight: 600;
}