.cinepoint-webapp {
  .p-button.p-button-link {
    color: #2563eb;
    background: transparent;
    border: transparent;
  }

  .p-button {
    color: #ffffff;
    background: #007bff;
    border: 1px solid #007bff;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
  }
}
