.cinepoint-webapp {

  @supports (-moz-appearance: none) {
    * {
      scrollbar-width: thin; // Firefox compatibility (the term width is general, not specific vertical/horizontal dimension)
      scrollbar-color: #EAECF0 transparent; /* Thumb color and track color */
    }
  }

  /* Custom scrollbar styles */
  *::-webkit-scrollbar {
    width: 4px; // Width of vertical scrollbar 
    height: 4px; // Height of horizontal scrollbar
  }

  *::-webkit-scrollbar-thumb {
    background-color: #EAECF0; /* Color of the scrollbar thumb */
    border-radius: 8px !important; /* Roundness of the scrollbar thumb */
    // width: 4px !important;
    // border: 4px solid transparent; /* Padding around the scrollbar thumb */

    &:hover {
      background: #555; /* Color of the scrollbar thumb when hovered */
    }
  }

}
