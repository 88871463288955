.cinepoint-webapp {
  p-dropdown.p-element {

    & > div.p-dropdown {
      @apply border rounded-lg border-neutral-300 px-[16px] py-[10px] h-auto items-center;

      & > span.p-dropdown-label {
        @apply border-0 p-0 mr-[8px];
      }

      & > div.p-dropdown-trigger {
        @apply p-0 w-auto mt-[4px];
      }
    }
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    @apply text-neutral-400;
  }

  .p-input-icon-right > p-dropdown + i:last-of-type {
    right: 48px;
  }

}
